module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nate Duff Blog","short_name":"N8 Duff Blog","start_url":"/","background_color":"#18453b","theme_color":"#18453b","display":"standalone","crossOrigin":"use-credentials","icon":"src/images/maskable/maskable_icon_x512.png","icons":[{"src":"src/images/maskable/maskable_icon_x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"src/images/maskable/maskable_icon_x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"src/images/maskable/maskable_icon_x128.png","sizes":"128x128","type":"image/png","purpose":"maskable"},{"src":"src/images/maskable/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"src/images/maskable/maskable_icon_x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"src/images/maskable/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"src/images/maskable/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"019c7fb4a990edaa09095ea0f22cf8ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/home/vsts/work/1/s/Client/static/sw.js","workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
