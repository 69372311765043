// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.less"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import wrapWithProvider from "./src/wrap-with-provider"

export const registerServiceWorker = () => true

export const onServiceWorkerUpdateReady = () => {
  // window.showInfo("Application updated - please refresh to see changes")
    const answer = window.confirm(
      `This application has been updated. ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
      window.location.reload()
    }
}

export const wrapRootElement = wrapWithProvider
