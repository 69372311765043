import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { CustomNavigationClient } from "./utils/navigationClient";

const msalInstance = new PublicClientApplication(msalConfig);

export default ({element}) => {
    const navigationClient = new CustomNavigationClient();
    msalInstance.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={msalInstance}>
            {element}
        </MsalProvider>
    );
}
